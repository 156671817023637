import { Alert, Button, Col, Radio, RadioChangeEvent, Row, Space, Typography, message } from 'antd'

import React, { useEffect, useState } from 'react'
import SlideCard from '../../components/Booking/SlideCard'
import UploadImg from '../../components/Booking/UploadImg'
import LoadingPage from '../../components/LoadingPage'
import {
  convertTime,
  getDocStatusByToken,
  getOmiselink,
  getReservationDataByToken,
} from '../../services/booking'
import { IresData } from './reservation.interface'
import { IuserData } from './user.interface'

import { CarOutlined, IdcardOutlined, QrcodeOutlined } from '@ant-design/icons'
import alipay from '../../static/img/icon/payment/Alipay.svg'
import master from '../../static/img/icon/payment/MasterCard.svg'
import visa from '../../static/img/icon/payment/Visa.svg'

import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import RadioOption from '../../components/Booking/RadioOption'
import driverCardLogo from '../../static/img/icon/booking/Driver.svg'
import passportLogo from '../../static/img/icon/booking/Passport.svg'
import { IdocStatus } from './docStatus.interface'

const { Text } = Typography
interface IradioOption {
  value: number
  label: string
  imgUrl: any[]
}

const styleIcon: React.CSSProperties = {
  fontSize: '20px',
  color: '#fff',
  marginLeft: '1rem',
}

const Booking: React.FC = () => {
  const { resNo } = useParams<{ resNo: string }>()

  const [resData, setResData] = useState<IresData | undefined>(undefined)
  const [userData, setUserData] = useState<IuserData | undefined>(undefined)

  const [nationalDocStatus, setNationalDocStatus] = useState<IdocStatus | null>(null)
  const [licenseDocStatus, setLicenseDocStatus] = useState<IdocStatus | null>(null)

  const [radioV, setRadioV] = useState(1)
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false)
  const [chargeSuccess, setChargeSuccess] = useState<boolean | undefined>(undefined)

  const verifyToken = localStorage.getItem('verifyToken')
  const bookingToken = localStorage.getItem('bookingToken')

  const handleChangeRadio = (e: RadioChangeEvent) => {
    setRadioV(e.target.value)
  }

  const listenPayment = async () => {
    try {
      const response = await getReservationDataByToken(verifyToken, bookingToken)
      const resInfo = response.data.data[0].reservationData
      const charge_last = resInfo.chargeData[0].chargestate

      if (charge_last) {
        if (charge_last.includes('CHARGED')) {
          setChargeSuccess(true)
          setResData(resInfo)
        }
      }
    } catch (error: any) {
      message.error(`Message Error: ${error}`)
    }
  }

  const payment = {
    verifyToken: localStorage.getItem('verifyToken'),
    // desc: 'DEPOSIT',
    chargemethod: 'OMISE',
    genlink: true,
    genlink_promptpay: false,
  }

  const handleConfirm = async () => {
    try {
      setLoadingPayment(true)
      let response
      if (radioV == 1) {
        response = await getOmiselink({ ...payment, channel: '' })
      } else {
        response = await getOmiselink({ ...payment, channel: 'ALIPAY' })
      }
      // console.log(response)
      setChargeSuccess(false)
      // const windowReference = window.open()
      // if (windowReference !== null) {
      //   windowReference.location = response.data.charge.link_url
      // }

      const { link_url }: any = response.data.charge
      window.location.replace(link_url)
    } catch (error) {
      message.error(`Message Error: ${error}`)
    } finally {
      setLoadingPayment(false)
    }
  }

  //detect the screen size
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)

  const option: IradioOption[] = [
    {
      value: 1,
      label: 'Credit / Debit cards',
      imgUrl: [visa, master],
    },
    {
      value: 2,
      label: 'Alipay',
      imgUrl: [alipay],
    },
  ]

  useEffect(() => {
    if (chargeSuccess === false) {
      const paymentInterval = setInterval(listenPayment, 3000)

      return () => {
        clearInterval(paymentInterval)
      }
    }
  }, [chargeSuccess])

  //landscape tablet break point
  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))
    getReservationDataByToken(verifyToken, bookingToken)
      .then((response) => {
        const result = response.data.data[0]
        setUserData(result.userData)
        setResData(result.reservationData)
      })
      .catch((error: any) => {
        message.error(`Message Error: Not Found Reservation Data !`)
        setTimeout(() => {
          window.location.replace('/')
        }, 3000)
      })

    getDocStatusByToken(verifyToken)
      .then((response) => {
        // console.log(response.data.data.docs)
        setNationalDocStatus(response.data.data.docs[0].status)
        setLicenseDocStatus(response.data.data.docs[3].status)
      })
      .catch((error: any) => {
        message.error(`Message Error: Not Found Document Status !`)
      })
  }, [])

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: '#0048B3',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {userData && resData ? (
        <>
          <div
            style={{
              marginTop: matches ? '1rem' : 0,
              width: matches ? '95%' : '100%',
              height: '43px',
              backgroundColor: '#1D2530',
              display: 'flex',
              alignItems: 'center',
              justifyContent: matches ? 'start' : 'space-between',
            }}
          >
            <div
              style={{ marginLeft: matches ? '2rem' : '1rem', marginRight: matches ? '2rem' : 0 }}
            >
              <Text style={{ fontSize: '11px', color: '#fff' }}>Customer verify</Text>
            </div>

            <div style={{ marginRight: matches ? 0 : '1rem' }}>
              <Text style={{ fontSize: '11px', color: '#fff' }}>
                Status :{' '}
                <Text
                  style={{
                    fontSize: '13px',
                    color:
                      userData.activated === 'TRUE' &&
                      resData.chargeData[0]?.chargestate.includes('CHARGED') &&
                      nationalDocStatus?.approveStatus.includes('APPROVED') &&
                      licenseDocStatus?.approveStatus.includes('APPROVED')
                        ? '#2ECC71'
                        : '#EA8181',
                    fontWeight: '600',
                  }}
                >
                  {userData.activated === 'TRUE' &&
                  resData.chargeData[0]?.chargestate.includes('CHARGED') &&
                  nationalDocStatus?.approveStatus.includes('APPROVED') &&
                  licenseDocStatus?.approveStatus.includes('APPROVED')
                    ? 'Complete'
                    : 'Pending'}
                </Text>
              </Text>
            </div>
          </div>

          <div
            style={{
              width: matches ? '75%' : '100%',
              backgroundColor: '#003C92',
              marginTop: matches ? '1rem' : 0,
              borderRadius: matches ? '10px' : 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '1rem',
              marginBottom: resData.depositprice_b == 0 ? '2rem' : '0',
            }}
          >
            {userData && resData && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '50px',
                    width: matches ? '80%' : '100%',
                    borderBottom: matches ? '0.5px solid #fff' : 0,
                  }}
                >
                  <Text style={{ color: '#fff', fontSize: '18px', marginLeft: '1rem' }}>
                    {`${resData.vehicleData.vehiclebrand}  ${resData.vehicleData.vehiclemodel}`}
                  </Text>
                </div>
                <SlideCard matches={matches} img={resData.vehiclePicture} />
                <div
                  style={{
                    width: matches ? '90%' : '95%',
                    backgroundColor: 'rgba(29, 37, 48, 0.2)',
                    margin: matches ? '0 0 1rem 0' : '1rem 0 1rem 0',
                    padding: '1rem 2rem 1rem 2rem',
                  }}
                >
                  <Row
                    style={{
                      fontSize: 14,
                      color: '#fff',
                    }}
                  >
                    <Col xs={10} md={6} lg={4}>
                      Reservation No
                    </Col>

                    <Col xs={10} md={5} lg={5}>
                      {`: ${resData?.reservationno}`}
                    </Col>

                    <Col xs={10} md={3} lg={2}>
                      Name
                    </Col>

                    <Col xs={10} md={5} lg={4}>
                      {`: ${userData.name} ${userData.lastname}`}
                    </Col>
                  </Row>

                  <Row
                    justify="space-around"
                    style={{
                      color: '#fff',
                      fontSize: 14,
                      width: '100%',
                      marginTop: '1rem',
                      backgroundColor: 'rgba(0, 55, 134, 1)',
                      zIndex: 5,
                    }}
                  >
                    <Col xs={10} md={5}>
                      <div>Start time</div>
                      <div>{convertTime(resData.initstarttime)}</div>
                    </Col>
                    <Col xs={10} md={5} style={{ marginBottom: matches ? 0 : '1rem' }}>
                      <div>Pick up location</div>
                      <div>SUV BKK Airport</div>
                    </Col>

                    {matches && (
                      <Col md={2}>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                          >
                            <path
                              d="M18.62 13.42C18.4346 13.6051 18.2875 13.8248 18.1872 14.0668C18.0868 14.3087 18.0352 14.5681 18.0352 14.83C18.0352 15.092 18.0868 15.3513 18.1872 15.5933C18.2875 15.8352 18.4346 16.055 18.62 16.24L26.38 24L18.62 31.76C18.2461 32.134 18.036 32.6412 18.036 33.17C18.036 33.6989 18.2461 34.2061 18.62 34.58C18.994 34.954 19.5012 35.1641 20.03 35.1641C20.5589 35.1641 21.0661 34.954 21.44 34.58L30.62 25.4C30.8055 25.215 30.9525 24.9952 31.0529 24.7533C31.1533 24.5113 31.2049 24.252 31.2049 23.99C31.2049 23.7281 31.1533 23.4687 31.0529 23.2268C30.9525 22.9848 30.8055 22.7651 30.62 22.58L21.44 13.4C20.68 12.64 19.4 12.64 18.62 13.42Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </Col>
                    )}

                    <Col xs={10} md={5}>
                      <div>End time</div>
                      <div>{convertTime(resData.initstoptime)}</div>
                    </Col>

                    <Col xs={10} md={4}>
                      <div>Return location</div>
                      <div>SUV BKK Airport</div>
                    </Col>
                  </Row>
                </div>

                <Link to={{ pathname: `/qr/${resNo}` }}>
                  <Button size="large" icon={<QrcodeOutlined />} style={{ borderRadius: 5 }}>
                    My QR
                  </Button>
                </Link>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '90%',
                    margin: '1rem 0 1rem 0',
                  }}
                >
                  <img src={passportLogo} alt="passport_logo" />
                  <Text
                    style={{
                      fontSize: matches ? '24px' : '18px',
                      color: '#ffffff',
                      marginLeft: '1rem',
                    }}
                  >
                    Passport
                  </Text>
                </div>

                <UploadImg
                  label="Passport"
                  uploadItems={[
                    {
                      name: 'Passport (user page)',
                      path: 'national_id',
                      icon: <IdcardOutlined style={styleIcon} />,
                      msgVerify: 'National ID card or Passport',
                      msgInprogress: 'National ID card or Passport',
                      msgReject: 'National ID card or Passport',
                      require: true,
                    },
                    {
                      name: 'Selfie with passport (user page)',
                      path: 'national_id_selfie',
                      icon: <IdcardOutlined style={styleIcon} />,
                      msgVerify: 'National ID card or Passport',
                      msgInprogress: 'National ID card or Passport',
                      msgReject: 'National ID card or Passport',
                      require: true,
                    },
                  ]}
                  matches={matches}
                  activated={userData.activated}
                  status={nationalDocStatus?.approveStatus}
                  // setDocStatus={setNationalDocStatus}
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '90%',
                    margin: '1rem 0 1rem 0',
                  }}
                >
                  <img src={driverCardLogo} alt="driver_logo" />
                  <Text
                    style={{
                      fontSize: matches ? '24px' : '18px',
                      color: '#ffffff',
                      marginLeft: '1rem',
                    }}
                  >
                    Driver license
                  </Text>
                </div>

                <UploadImg
                  label="Driver’s license"
                  uploadItems={[
                    {
                      name: `Driver's license`,
                      path: 'car_license',
                      icon: <CarOutlined style={styleIcon} />,
                      msgVerify: 'Car Driver License',
                      msgInprogress: 'Car Driver License',
                      msgReject: 'Car Driver License',
                      require: true,
                    },
                    {
                      name: `Driver's license 2 (Optional)`,
                      path: 'car_license_2',
                      icon: <CarOutlined style={styleIcon} />,
                      msgVerify: 'Car Driver License',
                      msgInprogress: 'Car Driver License',
                      msgReject: 'Car Driver License',
                    },
                    {
                      name: `Selfie with driver's license`,
                      path: `car_license_selfie`,
                      icon: <CarOutlined style={styleIcon} />,
                      msgVerify: 'Car Driver License',
                      msgInprogress: 'Car Driver License',
                      msgReject: 'Car Driver License',
                      require: true,
                    },
                  ]}
                  matches={matches}
                  activated={userData.activated}
                  status={licenseDocStatus?.approveStatus}
                  // setDocStatus={setLicenseDocStatus}
                />
              </>
            )}
          </div>

          {resData.chargeData.length > 0 &&
            resData.chargeData[0]?.chargestate.includes('CHARGED') &&
            resData.depositprice_b > 0 && (
              <Alert
                message="Already Paid the Deposit"
                type="success"
                showIcon
                style={{ margin: '2rem 0 2rem 0' }}
              />
            )}

          {!(
            resData.chargeData.length > 0 && resData.chargeData[0]?.chargestate.includes('CHARGED')
          ) &&
            resData.depositprice_b > 0 && (
              <>
                <div
                  style={{
                    width: matches ? '60%' : '100%',
                    height: '180px',
                    marginTop: matches ? '2rem' : 0,
                    backgroundColor: '#fff',
                    borderBottomLeftRadius: matches ? 0 : '40px',
                    borderBottomRightRadius: matches ? 0 : '40px',
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'var(--character-primary-85, rgba(0, 0, 0, 0.85))',
                      height: 68,
                      borderBottom: '1px solid #60ACFF',
                      backgroundColor: '#DDF0FF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
                      <div>Deposit payment</div>
                      <div>{`${resData.depositprice_b.toLocaleString()}B`}</div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '2rem 0 0 2rem',
                      color: 'var(--character-title-85, rgba(0, 0, 0, 0.85))',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  >
                    <Radio.Group value={radioV} onChange={handleChangeRadio}>
                      <Space direction="vertical">
                        {option.map((e, idx) => (
                          <RadioOption key={idx} {...e} />
                        ))}
                      </Space>
                    </Radio.Group>
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    marginTop: matches ? '2rem' : '3rem',
                    backgroundColor: '#ffffff',
                    height: 80,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      width: matches ? '70%' : '90%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: 13,
                          fontWeight: 300,
                        }}
                      >
                        Deposit payment
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >{`${resData.depositprice_b.toLocaleString()} B`}</div>
                    </div>

                    <Button
                      type="primary"
                      loading={loadingPayment}
                      style={{ width: matches ? 300 : 150 }}
                      onClick={handleConfirm}
                    >
                      Confirm & Pay
                    </Button>
                  </div>
                </div>
              </>
            )}
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  )
}

export default Booking
