import { Radio } from 'antd'
import React from 'react'

interface Iprops {
  value: number
  label: string
  imgUrl: any[]
}

const RadioOption: React.FC<Iprops> = ({ value, label, imgUrl }) => {
  return (
    <div
      style={{
        display: 'flex',
        // backgroundColor: 'red',
        height: '25px',
        overflow: 'hidden',
        width: '250px',
      }}
    >
      <Radio value={value}>
        <div>{label}</div>

        <div style={{ position: 'relative' }}>
          {imgUrl.map((e, idx) => {
            return (
              <div style={{ position: 'absolute', left: 100 + idx * 40, top: -20 }} key={idx}>
                <img src={e} alt="logo" />
              </div>
            )
          })}
        </div>
      </Radio>
    </div>
  )
}

export default RadioOption
