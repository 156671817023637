import axios from 'axios'
import config from '../../config'
const { restfulService, authService } = config

// export const getReservationToken = async (resNo: number, firstName: string, lastName: string) => {
//   const url = `${restfulService.URL}/customer-api/airport-transfer/login`
//   const reqBody = {
//     reservationNo: resNo,
//     firstName: firstName,
//     lastName: lastName,
//   }

//   return await axios.post(url, reqBody)
// }

// export const getBookingToken = async (verifyToken: string | undefined | null) => {
//   const url = `${restfulService.URL}/customer-api/booking/token`
//   const reqBody = {
//     verifyToken: verifyToken,
//   }
//   return await axios.post(url, reqBody)
// }

export const getOtp = async (reservationNo: string) => {
  const url = `${restfulService.URL}/customer-api/airport-transfer/otp/request`
  const reqBody = {
    reservationNo: reservationNo,
  }

  return await axios.post(url, reqBody)
}

export const getVerifyToken = async (
  resNo: string,
  otp: string | undefined,
  hash: string | undefined,
) => {
  const url = `${restfulService.URL}/customer-api/airport-transfer/otp-login`
  const reqBody = {
    reservationNo: resNo,
    otp: otp,
    hash: hash,
  }

  return await axios.post(url, reqBody)
}

export const getBookingToken = async (verifyToken: string | undefined) => {
  const url = `${restfulService.URL}/customer-api/booking/token`
  const reqBody = {
    verifyToken: verifyToken,
  }

  return await axios.post(url, reqBody)
}
