import { useEffect, useState } from 'react'

import { Button, Typography, message } from 'antd'

import { Link, useParams } from 'react-router-dom'
import logo from '../../static/img/icon/bua.png'

import QRCode from 'qrcode.react'
import LoadingPage from '../../components/LoadingPage'
import config from '../../config'
import { getRefreshToken } from '../../services/qr'

const { cmsService } = config

const { Text } = Typography

const QRcodePage = () => {
  const { resNo } = useParams<{ resNo: string }>()
  const nextUrl = `/booking/${resNo}`

  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)
  const [qrUrl, setQrUrl] = useState<string | undefined>(undefined)
  const [pageReady, setPageReady] = useState<boolean>(false)

  //landscape tablet break point
  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))

    //get refresh token every time when page start
    const verifyToken = localStorage.getItem('verifyToken')

    getRefreshToken(verifyToken)
      .then((response: any) => {
        const { verifyToken } = response.data
        localStorage.setItem('verifyToken', verifyToken)
        setQrUrl(
          `${cmsService.URL}/check-in-airport?step=0&info=${localStorage.getItem('bookingToken')}`,
        )
        setPageReady(true)
      })
      .catch((error: any) => {
        message.error(`Message Error: `, error)
        window.location.replace('/')
      })
  }, [])

  if (!pageReady) return <LoadingPage />

  return (
    <div
      style={{
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: '#0048B3',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            height: '43px',
            width: matches ? '98%' : '100%',
            backgroundColor: '#1D2530',
            display: 'flex',
            alignItems: 'center',
            marginTop: matches ? '2rem' : 0,
          }}
        >
          <Text
            style={{
              color: '#fff',
              fontSize: '11px',
              marginLeft: '2rem',
            }}
          >
            {matches ? 'Customer verify' : 'Reservation information'}
          </Text>
        </div>
        <div
          style={{
            width: matches ? '80%' : '90%',
            marginTop: '1rem',
            backgroundColor: '#003C92',
            display: matches ? 'flex' : '',
            flexWrap: matches ? 'wrap' : 'nowrap',
            justifyContent: matches ? 'center' : '',
            height: matches ? '' : '90%',

            borderRadius: matches ? '10px' : '20px',
            paddingBottom: matches ? '2rem' : 0,
          }}
        >
          <div
            style={{
              width: matches ? '80%' : '100%',
              height: matches ? 100 : 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: matches ? '0.5px solid #ffffff' : 0,
              // backgroundColor: 'red',
              marginTop: matches ? 0 : '1rem',
            }}
          >
            <img src={logo} alt="bua logo" width={matches ? '81' : '83'} />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: matches ? 'row' : 'column',
              justifyContent: matches ? 'center' : 'start',
              // backgroundColor: 'black',
            }}
          >
            <div
              style={{
                width: matches ? '45%' : '100%',
                height: 300,
                // backgroundColor: 'orange',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              {matches && (
                <div>
                  <Text
                    style={{
                      color: '#FBD50E',
                      fontSize: '15px',
                    }}
                  >
                    Scan at counter & verify yourself.
                  </Text>
                </div>
              )}

              {qrUrl && (
                <div
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <QRCode
                    value={qrUrl}
                    size={matches ? 170 : 200}
                    bgColor="rgb(255, 255, 255)"
                    fgColor="rgb(0, 0, 0)"
                  />
                </div>
              )}

              <div
                style={{
                  width: matches ? '60%' : '70%',
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: '9px',
                    color: '#fff',
                  }}
                >
                  Please present this QR code at the airport BUA car rental counter at level 1, gate
                  7 to verify the booking details.
                </Text>
              </div>
            </div>

            {matches && (
              <div
                style={{
                  height: '90%',
                  width: '2%',
                  marginRight: '2%',
                  borderRight: '0.5px solid #fff',
                }}
              ></div>
            )}

            <div
              style={{
                width: matches ? '45%' : '100%',
                height: matches ? 300 : 350,
                // backgroundColor: 'pink',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              {matches && (
                <div>
                  <Text
                    style={{
                      color: '#FBD50E',
                      font: '15px',
                    }}
                  >
                    Click link verify before meet staff.
                  </Text>
                </div>
              )}

              <div
                style={{
                  width: '80%',
                  textAlign: matches ? 'start' : 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: matches ? '15px' : '17px',
                    color: '#fff',
                    fontWeight: '600',
                  }}
                >
                  Fast check-in available to save your waiting time at the counter.
                </Text>
              </div>

              <div
                style={{
                  width: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Link
                  to={{ pathname: nextUrl }}
                  style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                >
                  <Button type="primary" style={{ width: matches ? '100%' : '50%' }}>
                    Start Verify
                  </Button>
                </Link>
              </div>

              <div
                style={{
                  width: matches ? '60%' : '70%',
                  textAlign: 'center',
                }}
              >
                <Text style={{ fontSize: '11px', color: '#ffffff', fontWeight: 100 }}>
                  to proceed with pre check-in procedures to verify your ID/passport and driving
                  license and to make deposit payment.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QRcodePage
