import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import routeconfig, { RouteItem } from '../../config/route'
import PreCheckIn from '../../containers/PreCheckIn'
import AuthenticatedPage from '../AuthenticatedPage'

class Router extends React.Component {
  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/">
          <PreCheckIn />
          {/* <Login/> */}
        </Route>

        {/* <Route exact path="/logout">
          <Logout />
        </Route> */}

        {/* <Route exact path="/host/reservation/:reservationNo" component={WebView} key="host" /> */}
        {routeconfig.map((item: RouteItem) => {
          const Container = (props?: any) => {
            if (item.prop) {
              return <item.container prop={item.prop} {...props} />
            } else {
              return <item.container {...props} />
            }
          }

          // const Module = (props: any) => {
          //   if (item?.module === 'host') {
          //     return (
          //       <WebView>
          //         <Container {...props} />
          //       </WebView>
          //     )
          //   } else {
          //     return (
          //       <Page>
          //         <Container />
          //       </Page>
          //     )
          //   }
          // }

          // const RenderComponent = (props: RouteComponentProps) => {
          //   if (item.requireAuth) {
          //     return (
          //       <AuthenticatedPage {...props}>
          //         <FreshChatModule>
          //           <Module {...props} />
          //         </FreshChatModule>
          //       </AuthenticatedPage>
          //     )
          //   } else {
          //     return <Module />
          //   }
          // }

          const RenderComponent = (props: RouteComponentProps) => {
            if (item.requireAuth) {
              return (
                <AuthenticatedPage {...props}>
                  <Container />
                </AuthenticatedPage>
              )
            } else {
              return <Container />
            }
          }

          return <Route exact path={item.path} key={item.title} component={RenderComponent} />
        })}
      </Switch>
    )
  }
}

export default Router
