import { Button, Col, Form, Input, Row, Spin, Typography } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { getBookingToken, getOtp, getVerifyToken } from '../../services/preCheckIn'
import buaLogo from '../../static/img/icon/bua.png'
import { Isubmit } from './interface'

const { Text, Title } = Typography

const Circle: React.FC<any> = ({ num, matches }: any) => {
  const size = matches ? '44px' : '32px'

  return (
    <div
      style={{
        backgroundColor: '#1890FF',
        border: '1px solid #1890FF',
        borderRadius: '50px',
        paddingTop: '8px',
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '1rem',
        flexShrink: '0',
      }}
    >
      <Title level={3} style={{ color: '#fff' }}>
        {num}
      </Title>
    </div>
  )
}

const Step: React.FC<any> = ({ num, matches, message }) => {
  return (
    <div
      style={{
        margin: '0 2rem 0 2rem',
        color: '#fff',
        display: 'flex',
        width: '80%',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Circle num={num} />
        <Text style={{ fontSize: matches ? '24px' : '16px', color: '#fff' }}>
          {message}

          {num === 1 && (
            <Text
              style={{
                fontWeight: '700',
                color: '#fff',
              }}
            >
              {' '}
              Trip.com
            </Text>
          )}
        </Text>
      </div>

      {num !== 4 && (
        <div
          style={{
            margin: '1rem 0 1rem 0.7rem',
            height: matches ? '76px' : '57px',
            width: '200px',
            borderLeft: '5px solid #58AFFF',
          }}
        ></div>
      )}
    </div>
  )
}

const WaitingData: React.FC = () => {
  return (
    <div style={{ display: 'flex', margin: '0 0 1rem 1rem' }}>
      <Spin />
      <div>
        <Text style={{ color: '#fff', margin: '0 0 0 1rem' }}>Loading Data</Text>
      </div>
    </div>
  )
}

const PreCheckIn: React.FC = () => {
  const [form] = Form.useForm()
  const [submitFail, setSubmitFail] = useState<boolean>(false)
  const [sendingOtp, setSendingOtp] = useState<boolean>(false)
  const [havingOtp, setHavingOtp] = useState<boolean>(false)
  const [resNo, setResNo] = useState<string | undefined>(undefined)
  const [hash, setHash] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const [seconds, setSeconds] = useState(60)
  const [timerRunning, setTimerRunning] = useState(false)

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = timeInSeconds % 60
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    if (timerRunning && seconds > 0) {
      const countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)

      return () => {
        clearInterval(countdownInterval)
      }
    }

    setTimerRunning(false)
    setSeconds(60)
  }, [timerRunning, seconds])

  //detect the screen size
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches)

  //landscape tablet break point
  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  const onFinish = async (values: Isubmit) => {
    const { reservationId, otp } = values
    if (otp === undefined) {
      return
    }

    setSubmitFail(false)
    setLoading(true)

    try {
      const verifyData = await getVerifyToken(reservationId, otp, hash)
      const { verifyToken } = verifyData.data
      const bookingData = await getBookingToken(verifyToken)
      const { bookingToken } = bookingData.data
      localStorage.setItem('verifyToken', verifyToken)
      localStorage.setItem('bookingToken', bookingToken)
      setResNo(reservationId)
    } catch (error: any) {
      setSubmitFail(true)
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    setSubmitFail(true)
  }

  const handleSendOtp = async () => {
    const value = form.getFieldValue('reservationId')

    try {
      const result = await getOtp(value)
      const { hash } = result.data
      setHash(hash)
      setTimerRunning(true)
      setSubmitFail(false)
      setHavingOtp(true)
    } catch (error) {
      // console.log(error)
      setSubmitFail(true)
    } finally {
      setSendingOtp(false)
    }
  }

  const handleInputChange = (e: any) => {
    // Remove any non-numeric characters
    e.target.value = e.target.value.replace(/\D/g, '')
  }

  const stepContent = [
    {
      num: 1,
      message: 'Purchased reservation on',
    },
    {
      num: 2,
      message: 'Click link for check-in online via SMS',
    },
    {
      num: 3,
      message: 'Check-in online with me',
    },
    {
      num: 4,
      message: 'Verify user on web link or at the airport',
    },
  ]

  const fontLable: React.CSSProperties = {
    fontSize: matches ? '14px' : '12px',
    fontWeight: '400px',
    color: '#fff',
  }

  if (resNo) {
    const url = `/qr/${resNo}`
    return <Redirect to={url} />
  }

  return (
    <div>
      <Row
        style={{
          backgroundColor: '#0048B3',
          minHeight: '100vh',
          paddingBottom: '10px',
        }}
      >
        <Col md={24} lg={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                height: matches ? '172px' : '101px',
                marginTop: matches ? '140px' : 0,
                borderTopRightRadius: matches ? '65px' : 0,
                borderBottomRightRadius: matches ? '65px' : 0,
                marginRight: matches ? '16px' : 0,
                backgroundColor: '#1D2530',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: matches ? '20px' : '10px',
                  left: matches ? '-30px' : '-20px',
                  zIndex: 2,
                  flexShrink: 0,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={matches ? '125' : '85'}
                  height={matches ? '125' : '85'}
                  viewBox="0 0 55 124"
                  fill="none"
                >
                  <path
                    d="M-43.5299 123.823C-50.3201 123.823 -57.1102 121.26 -62.2316 116.133C-72.5895 105.88 -72.5895 89.3047 -62.2316 79.0518L9.23754 8.24974C19.5954 -2.00317 36.3406 -2.00317 46.6984 8.24974C57.0563 18.5026 57.0563 35.1351 46.6984 45.3311L-24.8283 116.133C-30.0072 121.26 -36.7398 123.823 -43.5299 123.823Z"
                    fill="white"
                    fill-opacity="0.04"
                  />
                </svg>
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: matches ? '40px' : '22px',
                  left: matches ? '35px' : '25px',
                  zIndex: 2,
                  flexShrink: 0,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={matches ? '80' : '53'}
                  height={matches ? '80' : '53'}
                  viewBox="0 0 80 78"
                  fill="none"
                >
                  <path
                    d="M17.5078 77.41C13.2439 77.41 8.97995 75.8004 5.72781 72.5812C-0.776458 66.1428 -0.776458 55.7341 5.72781 49.2957L50.6434 4.83501C57.1477 -1.60338 67.699 -1.60338 74.1672 4.83501C80.6714 11.2734 80.6714 21.7179 74.1672 28.1205L29.2877 72.5812C26.0356 75.8004 21.7717 77.41 17.5078 77.41Z"
                    fill="white"
                    fill-opacity="0.04"
                  />
                </svg>
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: matches ? '20px' : '10px',
                  left: matches ? '78%' : '86%',
                  zIndex: 2,
                  flexShrink: 0,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={matches ? '125' : '85'}
                  height={matches ? '125' : '85'}
                  viewBox="0 0 125 124"
                  fill="none"
                >
                  <path
                    d="M27.0036 123.823C20.2135 123.823 13.4233 121.26 8.24443 116.133C-2.11338 105.88 -2.11338 89.3046 8.24443 79.0517L79.7709 8.24972C90.1287 -2.00318 106.931 -2.00318 117.232 8.24972C127.589 18.5026 127.589 35.1351 117.232 45.3311L45.7627 116.133C40.5838 121.26 33.7937 123.823 27.0036 123.823Z"
                    fill="white"
                    fill-opacity="0.04"
                  />
                </svg>
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: matches ? '32px' : '20px',
                  left: matches ? '71%' : '78%',
                  zIndex: 2,
                  flexShrink: 0,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={matches ? '79' : '53'}
                  height={matches ? '79' : '53'}
                  viewBox="0 0 79 78"
                  fill="none"
                >
                  <path
                    d="M16.7422 77.41C12.4783 77.41 8.21434 75.8004 4.99833 72.5812C-1.50596 66.1428 -1.50596 55.7341 4.99833 49.2957L49.8779 4.83501C56.3822 -1.60338 66.8975 -1.60338 73.4018 4.83501C79.9061 11.2734 79.9061 21.7179 73.4018 28.1205L28.486 72.5812C25.2339 75.8004 21.0061 77.41 16.7422 77.41Z"
                    fill="white"
                    fill-opacity="0.04"
                  />
                </svg>
              </div>

              <div
                style={{
                  // backgroundColor: 'red',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: matches ? 'start' : 'center',
                  marginLeft: matches ? '4rem' : 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem',
                    // justifyContent: matches ? 'start' : 'center',
                  }}
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={matches ? '42' : '22'}
                      height={matches ? '42' : '22'}
                      viewBox="0 0 42 42"
                      fill="none"
                    >
                      <path
                        d="M25.8554 41.4813C24.7712 41.4813 23.687 41.0681 22.86 40.2418C21.2062 38.5891 21.2062 35.9173 22.86 34.2646L34.2809 22.852C35.9348 21.1994 38.6086 21.1994 40.2624 22.852C41.9163 24.5047 41.9163 27.1765 40.2624 28.8292L28.8507 40.2418C28.0238 41.0681 26.9396 41.4813 25.8554 41.4813Z"
                        fill="white"
                      />
                      <path
                        d="M4.22656 41.4813C3.14236 41.4813 2.05815 41.0681 1.2404 40.2418C-0.413468 38.5891 -0.413468 35.9173 1.2404 34.2646L12.6613 22.852C14.3152 21.1994 16.9889 21.1994 18.6428 22.852C20.2967 24.5047 20.2967 27.1765 18.6428 28.8292L7.21272 40.2418C6.38578 41.0681 5.31076 41.4813 4.22656 41.4813Z"
                        fill="white"
                      />
                      <path
                        d="M25.8554 19.8686C24.7712 19.8686 23.687 19.4554 22.86 18.6291C21.2062 16.9764 21.2062 14.3046 22.86 12.652L34.2809 1.23938C35.9348 -0.413289 38.6178 -0.413289 40.2624 1.23938C41.9163 2.89204 41.9163 5.57303 40.2624 7.21652L28.8507 18.6291C28.0238 19.4554 26.9396 19.8686 25.8554 19.8686Z"
                        fill="white"
                      />
                      <path
                        d="M4.22658 19.8687C3.14237 19.8687 2.05816 19.4555 1.24041 18.6292C-0.413469 16.9766 -0.413469 14.3047 1.24041 12.6521L12.6522 1.2395C14.306 -0.413166 16.9798 -0.413166 18.6337 1.2395C20.2876 2.89217 20.2876 5.57316 18.6337 7.21664L7.21274 18.6292C6.3858 19.4555 5.31078 19.8687 4.22658 19.8687Z"
                        fill="white"
                      />
                    </svg>
                  </div>

                  <div
                    style={{
                      width: matches ? '2rem' : '1rem',
                      height: matches ? '60%' : '40%',
                      marginRight: matches ? '2rem' : '1rem',
                      borderRight: '2px solid #fff',
                    }}
                  ></div>

                  <div>
                    <img src={buaLogo} alt="bua" width={matches ? 160 : 82} />
                  </div>
                </div>

                <Text
                  style={{ fontSize: matches ? '32px' : '14px', fontWeight: '600', color: '#fff' }}
                >
                  Check-in online car rental
                </Text>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: matches ? 'column' : 'column-reverse',
              }}
            >
              <Row
                style={{
                  marginTop: matches ? '18px' : '26px',
                  marginBottom: '28px',
                }}
              >
                <Col offset={2} span={20}>
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#fff',
                    }}
                  >
                    Enter your Reservation ID to start. Check in at the respective website. For
                    getting QR code, please verify your information with link in website or verify
                    at the counter.
                  </Text>
                </Col>
              </Row>

              <Row
                style={{
                  marginTop: matches ? 0 : '8px',
                }}
              >
                <Col offset={2} span={18}>
                  {loading && <WaitingData />}

                  {submitFail && (
                    <Text
                      type="danger"
                      style={{
                        marginBottom: '1rem',
                      }}
                    >
                      Your information is not correct, please check your reservation ID
                    </Text>
                  )}

                  <Form
                    name="basic"
                    wrapperCol={{ span: 20 }}
                    form={form}
                    // initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Text style={fontLable}>Enter Reservation ID</Text>
                    <Form.Item
                      wrapperCol={{ span: 18 }}
                      // label="Reservation ID"
                      name="reservationId"
                      rules={[{ required: true, message: '' }]}
                    >
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Input placeholder="xxxxxxxxxxx / xxxxxx" />
                        <Button
                          type="primary"
                          disabled={timerRunning}
                          onClick={() => {
                            setSendingOtp(true)
                            handleSendOtp()
                          }}
                          loading={sendingOtp}
                        >
                          {timerRunning ? `Resend OTP in ${formatTime(seconds)} s` : 'SEND OTP'}
                        </Button>
                      </div>
                    </Form.Item>

                    {havingOtp && (
                      <>
                        <Text style={fontLable}>Input OTP</Text>

                        <Row>
                          <Col span={12}>
                            <Form.Item name="otp" rules={[{ required: true, message: '' }]}>
                              <Input
                                maxLength={6}
                                onInput={handleInputChange}
                                placeholder="123456790"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                          <Button type="primary" htmlType="submit">
                            Confirm
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col md={24} lg={12}>
          <Row
            justify="center"
            style={{
              backgroundColor: '#003C92',
              borderRadius: matches ? '8px' : '35px',
              margin: '5px',
              paddingTop: matches ? '100px' : '36px',
              minHeight: '100%',
              overflow: 'hidden',
            }}
          >
            <Col span={22}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: '2rem',
                    marginBottom: matches ? '50px' : '20px',
                  }}
                >
                  <Text
                    style={{
                      fontSize: matches ? '40px' : '20px',
                      fontWeight: matches ? '500px' : '600px',
                      color: '#fff',
                    }}
                  >
                    Step to checking
                  </Text>
                </div>

                {stepContent.map((e) => {
                  return (
                    <>
                      <Step key={e.num} num={e.num} matches={matches} message={e.message} />
                    </>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default PreCheckIn
