import axios from 'axios'
import config from '../../config'
const { restfulService } = config

export const uploadImg = async (img: File, verifyToken: any, pathName: any) => {
  const fd = new FormData()
  fd.append('fileImage', img)
  fd.append('verifyToken', verifyToken)
  fd.append('cardNumber', '')
  fd.append('issueDate', '')
  fd.append('expirationDate', '')

  const customHeader = {
    'Content-Type': 'multipart/form-data',
  }

  return await axios.post(`${restfulService.URL}/customer-api/upload/file/${pathName}`, fd, {
    headers: customHeader,
  })
}

export const getReservationDataByToken = async (verifyToken: any, bookingToken: any) => {
  const reqBody = {
    verifyToken: verifyToken,
    bookingToken: bookingToken,
  }

  return await axios.post(`${restfulService.URL}/customer-api/booking/data`, reqBody)
}

export const convertTime = (inputDateString: string) => {
  // Parse the input date string
  const parsedDate = new Date(inputDateString)

  // Define arrays for month names and days of the week
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const year = parsedDate.getFullYear()
  const month = parsedDate.getMonth() // Returns a zero-based index
  const day = parsedDate.getDate()
  const hours = parsedDate.getHours()
  const minutes = parsedDate.getMinutes()

  // Format the date in the desired format
  const formattedDate = `${day} ${monthNames[month]} ${year} ${hours}:${minutes}`

  return formattedDate
}

export const getDocStatusByToken = async (verifyToken: any) => {
  const reqBody = {
    verifyToken: verifyToken,
  }

  return await axios.post(`${restfulService.URL}/customer-api/document/status`, reqBody)
}

export const validateTimeRange = (startDate: any, stopDate: any) => {
  const startTime = new Date(startDate).getTime()
  const stopTime = new Date(stopDate).getTime()

  if (startTime <= stopTime) return true

  return false
}

export const getOmiselink = async (posData: any) => {
  return await axios.post(`${restfulService.URL}/customer-api/payment`, posData)
}
