import haupGif from '../../static/img/haup-loading.gif'

const LoadingPage = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: '#003C92',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={haupGif} alt="loading_logo" style={{ width: '30%', height: '30%' }} />
    </div>
  )
}

export default LoadingPage
