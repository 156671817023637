import {
  CameraOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  FileImageOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { Button, Form, Spin, Typography, Upload, message } from 'antd'
import React, { useState } from 'react'
import { IuploadItem } from '../../../../containers/Booking/uploadItem.interface'
import { getDocStatusByToken, uploadImg } from '../../../../services/booking'

const { Text } = Typography

interface Iprops {
  item: IuploadItem
  activated: string
  status: string | undefined
}

interface IpropsStatusBar {
  colorHex: string
  itemIcon: JSX.Element
  msg: string
  verifyIcon: JSX.Element
}

const StatusBar: React.FC<IpropsStatusBar> = ({ colorHex, itemIcon, msg, verifyIcon }) => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '66px',
        backgroundColor: `${colorHex}`,
        border: '1px solid var(--neutral-5, #D9D9D9)',
        borderRadius: '2px',
        marginTop: '1rem',
        marginBottom: '1rem',
        paddingRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 25,
      }}
    >
      {itemIcon}
      <Text style={{ color: '#fff' }}>{msg}</Text>
      {verifyIcon}
    </div>
  )
}

const UploadItem: React.FC<Iprops> = ({ item, activated, status }) => {
  const [preView, setPreView] = useState<any>(undefined)
  const [loadingSpin, setLoadingSpin] = useState<boolean>(false)

  const handleChange = (values: any) => {
    const previewUrl = URL.createObjectURL(values.file.originFileObj)
    setPreView(previewUrl)
  }

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    try {
      setLoadingSpin(true)

      const verifyToken = localStorage.getItem('verifyToken')
      const response = await uploadImg(file, verifyToken, item.path)
      console.log(response)
      const docStatusData = await getDocStatusByToken(verifyToken)
      console.log(docStatusData)

      setLoadingSpin(false)
      onSuccess('OK')
      message.success(`${file.name} file uploaded successfully`)
    } catch (error) {
      console.log(error)
      onError(error)
      message.error(`${file.name} file upload failed.`)
    } finally {
      setLoadingSpin(false)
    }
  }

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file types!')
    }
    return isJpgOrPng
  }

  return (
    <>
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} labelAlign="left">
        <Form.Item name="front" label={item.name} required={item.require}>
          {status === 'APPROVED' && (
            <StatusBar
              colorHex={'#2ECC71'}
              itemIcon={item.icon}
              msg={item.msgVerify}
              verifyIcon={<CheckOutlined style={{ color: '#fff', fontSize: 16 }} />}
            />
          )}

          {status === 'IN_PROGRESS' && (
            <StatusBar
              colorHex="#EB984E"
              itemIcon={item.icon}
              msg={item.msgInprogress}
              verifyIcon={<MinusCircleOutlined style={{ color: '#fff', fontSize: 16 }} />}
            />
          )}

          {status === 'REJECT' && (
            <>
              <Upload
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                accept=".jpg, .png"
                type="select"
                // disabled={invalidInput && dateForm}
                onChange={handleChange}
                showUploadList={false}
              >
                <Button style={{ marginRight: '1rem' }}>
                  <UploadOutlined />
                  {' | '}
                  <CameraOutlined />
                  Upload File
                </Button>
              </Upload>
              <StatusBar
                colorHex="#E74C3C"
                itemIcon={item.icon}
                msg={item.msgReject}
                verifyIcon={<CloseCircleOutlined style={{ color: '#fff', fontSize: 16 }} />}
              />
            </>
          )}

          {status !== 'APPROVED' && status !== 'IN_PROGRESS' && status !== 'REJECT' && (
            <>
              <Upload
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                accept=".jpg, .png"
                type="select"
                // disabled={invalidInput && dateForm}
                onChange={handleChange}
                showUploadList={false}
              >
                <Button style={{ marginRight: '1rem' }}>
                  <UploadOutlined />
                  {' | '}
                  <CameraOutlined />
                  Upload File
                </Button>
              </Upload>
              <div
                style={{
                  width: '100%',
                  height: '66px',
                  backgroundColor: '#F3F3F3',
                  border: '1px solid var(--neutral-5, #D9D9D9)',
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '1rem 0 1rem 0',
                }}
              >
                <FileImageOutlined style={{ marginLeft: '2rem', fontSize: '20px' }} />
              </div>
            </>
          )}
        </Form.Item>
      </Form>

      {loadingSpin && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spin tip="Uploading Image" style={{ marginBottom: '1rem' }} />
        </div>
      )}

      {preView && !loadingSpin && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}
        >
          <img src={preView} alt="example" width={200} />
        </div>
      )}

      {/* <Row>
        {activated !== 'TRUE' ? (
          <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} labelAlign="left">
            <Form.Item name="front" label={item.name} required={item.require}>
              <Upload
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                accept=".jpg, .png"
                type="select"
                // disabled={invalidInput && dateForm}
                onChange={handleChange}
                showUploadList={false}
              >
                <Button style={{ marginRight: '1rem' }}>
                  <UploadOutlined />
                  {' | '}
                  <CameraOutlined />
                  Upload File
                </Button>
              </Upload>
            </Form.Item>
          </Form>
        ) : (
          <>
            <Text>{`${item.name} :`}</Text>
            <div
              style={{
                width: '100%',
                minHeight: '66px',
                backgroundColor: '#2ECC71',
                border: '1px solid var(--neutral-5, #D9D9D9)',
                borderRadius: '2px',
                marginTop: '1rem',
                marginBottom: '1rem',
                paddingRight: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: 25,
              }}
            >
              {item.icon}
              <Text style={{ color: '#fff' }}>{item.msgVerify}</Text>
              <CheckOutlined style={{ color: '#fff', fontSize: 16 }} />
            </div>
          </>
        )}

        {activated !== 'TRUE' && !loadingSpin && (
          <>
            <div
              style={{
                width: '100%',
                height: '66px',
                backgroundColor: '#F3F3F3',
                border: '1px solid var(--neutral-5, #D9D9D9)',
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <FileImageOutlined style={{ marginLeft: '2rem', fontSize: '20px' }} />
            </div>
          </>
        )}

        {loadingSpin && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Spin tip="Uploading Image" style={{ marginBottom: '1rem' }} />
          </div>
        )}

        {preView && !loadingSpin && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '1rem',
            }}
          >
            <img src={preView} alt="example" width={200} />
          </div>
        )}
      </Row> */}
    </>
  )
}

export default UploadItem
