import axios from 'axios'
import config from '../../config'
const { restfulService } = config

export const getRefreshToken = async (verifyToken: string | undefined | null) => {
  const url = `${restfulService.URL}/customer-api/airport-transfer/token/verify`
  const reqBody = {
    verifyToken: verifyToken,
  }

  return axios.post(url, reqBody)
}
